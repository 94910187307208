import styled, { css } from 'styled-components';
import Colors from '../../../Colors';

export const Checkbox = styled.span<{ error?: string | boolean; buttonOptionTheme?: boolean }>`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};
  stroke-width: 2px;
  border: solid 1px ${props => (props.error ? Colors.error : Colors.black)};

  ${props =>
    props.buttonOptionTheme &&
    css`
      display: none;
    `}
`;

export const StyledRadioButton = styled.span<{ error?: string | boolean; buttonOptionTheme?: boolean }>`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 50%;
  background: ${Colors.white};
  border: solid 1px ${props => (props.error ? Colors.error : '#bebebe')};
  ${props =>
    props.buttonOptionTheme &&
    css`
      display: none;
    `}
`;

export const CheckboxItemContainer = styled.div<{
  checked: boolean;
  fullWidth?: boolean;
  noWidth?: boolean;
  disabled?: boolean;
  noMargin?: boolean;
  marginLeft?: number;
  reversed?: boolean;
  itemWidth?: number;
  noSpaceArround?: boolean;
  flexAlign?: string;
  buttonOptionTheme?: boolean;
}>`
  width: ${props => (props.fullWidth ? '100%' : `${props.itemWidth ?? 140}px`)};
  ${props =>
    props.noWidth &&
    css`
      width: auto;
    `}
  display: flex;
  ${props =>
    props.reversed &&
    css`
      flex-direction: row-reverse;
    `}
  gap: 8px;
  margin-top: 8px;
  user-select: none;
  position: relative;
  cursor: pointer;
  ${props => props.noSpaceArround && 'margin-top: 0; gap: 0;'}
  &:hover {
    ${props =>
      !props.checked &&
      css`
        svg {
          visibility: visible;
          stroke: ${Colors.grey};
        }

        ${Checkbox} {
          background: ${Colors.grey};
        }
      `}
  }
  ${props =>
    props.buttonOptionTheme &&
    css`
      width: auto;
    `}

  p {
    ${props =>
      props.buttonOptionTheme &&
      css`
        background-color: ${Colors.toolsBarBackgroundColor};
        border-radius: 8px;
        padding: 6px 10px 7px 10px;
        text-decoration: none;
        color: ${Colors.black};
        font-size: 12px;
        text-align: center;
        font-weight: 700;
        width: auto;
      `}
    ${props =>
      props.buttonOptionTheme &&
      props.checked &&
      css`
        background-color: ${Colors.black};
        color: ${Colors.white};
        width: auto;
      `}
  }

  ${props =>
    props.checked &&
    css`
      svg {
        visibility: visible;
        height: 16px;
        width: 16px;
        stroke: ${Colors.black};
      }
      ${StyledRadioButton} {
        background: ${Colors.black};
        border: 1px solid ${Colors.black};
        &::after {
          content: '';
          display: block;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          margin: 3px;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
          background: white;
        }
      }
    `}
  ${props =>
    !props.checked &&
    css`
      &:hover ${StyledRadioButton} {
        background: #bebebe;
        &::after {
          content: '';
          display: block;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          margin: 3px;
          background: #eeeeee;
        }
      }

      &:focus-visible ${StyledRadioButton} {
        background: ${Colors.placeholder};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      &:hover {
        svg {
          visibility: hidden;
        }
        ${Checkbox} {
          background: ${Colors.white};
        }
      }
    `}

  ${props =>
    props.noMargin &&
    css`
      margin-top: 0;
    `}

    margin-left: ${props => (props.marginLeft ? `${props.marginLeft}px` : '0')};
  align-items: ${props => props.flexAlign || 'center'};
`;

export const CheckboxFormWrapper = styled.div<{ isOdd?: boolean; alternate?: boolean; buttonOptionTheme?: boolean }>`
  padding: 4px 6px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  ${props =>
    props.alternate &&
    css`
      background-color: ${props.isOdd ? `${Colors.white}` : '#f3f3f3'};
      &:nth-child(odd) {
        background-color: ${Colors.white};
      }
      &:nth-child(even) {
        background-color: #f3f3f3;
      }
    `}
  ${props =>
    props.buttonOptionTheme &&
    css`
      width: auto;
    `}
`;

export const CheckboxFrom = styled.label<{ width?: number; buttonOptionTheme?: boolean }>`
  display: flex;
  align-items: center;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    display: none;
  }

  input[type='radio'] {
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    display: none;
  }
  ${props =>
    props.buttonOptionTheme &&
    css`
      width: auto;
    `}
`;
export const CheckboxFromContainer = styled.div<{ column?: boolean; noFlexWrap?: boolean; buttonOptionTheme?: boolean; buttonsGap?: string }>`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  flex-wrap: ${props => (props.noFlexWrap ? 'normal' : 'wrap')};
  gap: 10px;
  ${props =>
    props.buttonOptionTheme &&
    css`
      flex-direction: row;
      flex-flow: wrap;
      max-height: 60vh;
      /* overflow-y: scroll; */
      gap: ${props.buttonsGap || '0'};
    `}
`;

export const CheckboxItemName = styled.p<{ error?: string | boolean; fontSize?: number; disabled?: boolean }>`
  font-size: 16px;
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
  font-weight: bold;
  color: ${props => (props.error ? Colors.error : Colors.black)};
  ${props =>
    props.disabled &&
    css`
      color: ${Colors.greyOutTitle};
    `}
`;

export const CheckboxItemDescription = styled.span<{ error?: string | boolean; fontSize?: number; disabled?: boolean }>`
  font-size: 13px;
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
  color: ${props => (props.error ? Colors.error : Colors.greyOutTitle)};
  ${props =>
    props.disabled &&
    css`
      color: ${Colors.greyOutTitle};
    `}
`;

export const SwitchButton = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
  &:hover {
    transform: scale(1.05);
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;

    ::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
  input {
    display: none;
    &:checked + span {
      background-color: ${Colors.primary};
      ::before {
        transform: translateX(26px);
      }
    }
    &:focus + span {
      box-shadow: 0 0 1px ${Colors.primary};
    }
  }
`;
